<template>
  <a-layout style="padding: 0px">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        okText="确定"
        @ok="onout"
        :closable="false"
        :maskClosable="false"
        :visible="logvisible"
        :cancel-button-props="{ props: { disabled: true } }"
      >
        登录已失效，请前往重新登陆
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>基础配置</a-breadcrumb-item>
            <a-breadcrumb-item>组织架构</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visible == ''" class="Enter-top-right">
          <a-input-search
            placeholder="请输入搜索内容"
            style="width: 150px"
            @search="onSearch"
          />
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: 'auto'">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: 'auto',
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div>
            <div class="Arch-bottom" v-if="!visible">
              <div class="Arch-bottom-left">
                <div class="bottom-left-title">组织架构</div>
                <a-button
                  @click="showArchDrawer"
                  :disabled="forms.enterpriseId != '' ? false : true"
                  style="width: 200px; margin: 10px 50px"
                  type="primary"
                >
                  添加部门
                </a-button>
                <a-dropdown
                  @visibleChange="visibleChange"
                  :trigger="['contextmenu']"
                >
                  <div style="width: 0">
                    <a-tree
                      v-if="treeData.length > 0"
                      :replace-fields="replaceFields"
                      :tree-data="treeData"
                      :value="title"
                      :defaultExpandAll="true"
                      @select="onSelect"
                      @rightClick="onRightClick"
                    >
                    </a-tree>
                  </div>
                  <a-menu v-show="rightvisible" slot="overlay">
                    <!--  -->
                    <a-menu-item @click="showArchDrawer" key="1">
                      添加子部门
                    </a-menu-item>
                    <a-menu-item @click="onArchEdit" key="1">
                      编辑部门
                    </a-menu-item>
                    <a-menu-item key="2">
                      <a-popconfirm
                        title="你确认要删除此部门?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="Archdetail"
                        @cancel="cancel"
                      >
                        删除部门
                      </a-popconfirm>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
              <div class="Arch-bottom-right">
                <div class="rightarch-top">
                  <!-- <div
                    v-if="usersname.name != ''"
                    style="padding-left:60px;font-size:15px;font-weight:600"
                  >
                    {{ usersname.name }}-员工列表
                  </div>
                  <div
                    style="padding-left:60px;font-size:15px;font-weight:600"
                    v-else
                  >
                    尚未选中组织架构
                  </div> -->
                  <a-button
                    style="position: absolute; right: 0; top: 20px"
                    type="primary"
                    v-show="params.architectureId"
                    @click="onInvite"
                  >
                    邀请加入
                  </a-button>
                </div>
                <div class="rightarch-bottom">
                  <a-table
                    :selectedRowKeys="selectedRowKey"
                    :row-selection="rowSelection"
                    rowKey="id"
                    :pagination="false"
                    :columns="columns"
                    :data-source="Userlist"
                  >
                    <a slot="gender" slot-scope="item">{{
                      item == '0' ? '未知' : item == '1' ? '男' : '女'
                    }}</a>
                    <span
                      v-show="params.architectureId != ''"
                      slot="action"
                      slot-scope="text, record"
                    >
                      <a-popconfirm
                        title="你确认要删除该成员么?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="onDetele(record)"
                        @cancel="cancel"
                      >
                        <a>删除</a>
                      </a-popconfirm>
                    </span>
                  </a-table>
                  <a-pagination
                    v-model="params.current"
                    :page-size="params.pageSize"
                    show-quick-jumper
                    :default-current="2"
                    :total="total"
                    @change="onpagesize"
                  />
                </div>
                <a-modal
                  v-model="visibles"
                  okText="确定"
                  cancelText="取消"
                  title="邀请加入"
                  @ok="handleOk"
                >
                  <a-form-model
                    :model="form"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol"
                  >
                    <a-form-model-item label="员工">
                      <a-select
                        show-search
                        :filter-option="filterOption"
                        v-model="userId"
                      >
                        <a-select-option
                          v-for="item in userlist"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-form-model>
                </a-modal>
              </div>
            </div>
            <div v-else>
              <a-form-model
                ref="ruleForms"
                :rules="ruless"
                :model="forms"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item ref="name" prop="name" label="名称">
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="forms.name"
                  />
                </a-form-model-item>
                <a-form-model-item ref="code" prop="code" label="编号">
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="forms.code"
                  />
                </a-form-model-item>
                <a-form-model-item
                  ref="contactName"
                  prop="contactName"
                  label="联系人"
                >
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="forms.contactName"
                  />
                </a-form-model-item>
                <a-form-model-item
                  ref="contactMobile"
                  prop="contactMobile"
                  label="联系人电话"
                >
                  <a-input
                    @blur="
                      () => {
                        $refs.name.onFieldBlur()
                      }
                    "
                    v-model="forms.contactMobile"
                  />
                </a-form-model-item>
                <a-form-item :wrapper-col="{ span: 8, offset: 8 }">
                  <a-button type="primary" @click="onSubmit"> 提交 </a-button>
                  <a-button @click="onClose" style="margin-left: 10px">
                    取消
                  </a-button>
                </a-form-item>
              </a-form-model>
            </div>
          </div>
        </div>
        <!-- 抽屉 -->
        <!-- <div>
          <a-drawer
            :title="title"
            :placement="placement"
            :closable="false"
            :visible="visible"
            @close="onClose"
            width="550"
          >
            
          </a-drawer>
        </div> -->
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '用户名',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' },
  },
  {
    title: '性别',
    dataIndex: 'gender',
    scopedSlots: { customRender: 'gender' },
  },
  {
    title: '编号',
    dataIndex: 'code',
  },
  {
    title: '邮件',
    dataIndex: 'email',
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import http from '../../../http'
export default {
  data() {
    return {
      change: false,
      rightvisible: false,
      treeData: [],
      replaceFields: {
        children: 'children',
        title: 'title',
        value: 'key',
      },
      headers: {
        authorization: 'authorization-text',
      },
      // 行内
      Userlist: [],
      //邀请列表
      logvisible: false,
      userlist: [],
      columns,
      title: '',
      visible: false,
      visibles: false,
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      // 要传参数
      selectedRowKey: [],
      params: {
        current: 1,
        pageSize: 7,
        architectureId: '',
      },
      total: 6,
      Id: '',
      userId: '',
      form: {
        id: '',
        username: '',
        name: '',
        gender: '1',
        state: '',
        code: '',
        email: '',
        mobile: '',
      },
      usersname: {
        name: '',
      },
      forms: {
        id: '',
        name: '',
        code: '',
        state: 0,
        contactName: '',
        contactMobile: '',
        enterpriseId: '',
        superiorId: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: '用户名不能为空',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '真实姓名不能为空',
            trigger: 'blur',
          },
        ],
        gender: [
          {
            required: true,
            message: '性别不能为空',
            trigger: 'change',
          },
        ],
        code: [
          {
            required: true,
            message: '编号不能为空',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: '邮件不能为空',
            trigger: 'blur',
          },
        ],
        mobile: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur',
          },
        ],
      },
      ruless: {
        name: [
          {
            required: true,
            message: '名称不能为空',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '编号不能为空',
            trigger: 'blur',
          },
        ],
        contactName: [
          {
            required: true,
            message: '联系人不能为空',
            trigger: 'blur',
          },
        ],
        contactMobile: [
          {
            required: true,
            message: '联系人电话不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {},
  methods: {
    //查看密码
    oninitPassword(e) {
      console.log(e)
    },
    //邀请
    handleOk() {
      this.getInvitUser(this.params.architectureId, this.userId)
      this.visibles = false
    },
    onInvite() {
      this.visibles = true
    },
    onRightClick(key) {
      if (key.node.value == null) {
        this.rightvisible = false
        this.params.architectureId = ''
        this.forms.enterpriseId = key.node.eventKey
        return
      } else {
        this.rightvisible = true
        this.params.architectureId = [key.node.eventKey]
        this.forms.enterpriseId = ''
      }
      //   console.log(key.node.value);
      //   console.log(key.node.eventKey);
    },
    visibleChange(e) {
      if (e == false) {
        this.rightvisible = false
        this.params.architectureId = ''
      }
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    onSearch(value) {
      console.log(value)
    },
    //删除
    Archdetail() {
      this.deleteArchitecture(this.params.architectureId)
    },
    async getArchiteid(id) {
      try {
        const res = await http.getArchiteid(id)
        const { success, data } = res.data
        if (success) {
          this.Id = data.architecture.id
          this.forms.id = data.architecture.id
          this.forms.name = data.architecture.name
          this.forms.code = data.architecture.code
          this.forms.contactName = data.architecture.contactName
          this.forms.contactMobile = data.architecture.contactMobile
          this.forms.enterpriseId = data.architecture.enterpriseId
          this.forms.superiorId = data.architecture.superiorId
          // this.form.superiorName=data.superiorName;
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async putArchitecture(form) {
      try {
        const res = await http.putArchitecture(form)
        const { success, msg } = res.data
        if (success) {
          this.getArchitectlist()
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    async AddArchitecture(form) {
      try {
        const res = await http.AddArchitecture(form)
        const { success, msg } = res.data
        if (success) {
          this.getArchitectlist()
          this.$message.success('提交成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    onDetele(e) {
      this.deleteuserarea([e.id])
    },
    async deleteuserarea(id) {
      try {
        const res = await http.deleteuserarea(this.params.architectureId, id)
        const { success, msg } = res.data
        if (success) {
          this.getarchitectureUser(this.params.architectureId)
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.selectedRowKey = []
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    onSelect(selectedKeys, info) {
      this.usersname.name = info.node.title
      if (info.node.value == null) {
        this.params.architectureId = ''
        this.forms.enterpriseId = selectedKeys[0]
        this.Userlist = []
        return
      } else {
        this.params.architectureId = selectedKeys[0]
        this.forms.enterpriseId = ''
        this.getarchitectureUser(this.params.architectureId)
      }
    },
    async getArchitectlist() {
      try {
        const res = await http.getArchitectlist()
        const { success, data } = res.data
        if (success) {
          this.treeData = data.list
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
    },
    onContextMenuClick(treeKey, menuKey) {
      console.log(`treeKey: ${treeKey}, menuKey: ${menuKey}`)
    },
    onClose() {
      this.change = false
      this.Id = ''
      this.forms.id = ''
      this.forms.name = ''
      this.forms.code = ''
      this.forms.state = ''
      this.forms.contactName = ''
      this.forms.contactMobile = ''
      this.forms.enterpriseId = ''
      this.forms.superiorId = ''
      this.form.id = ''
      this.form.username = ''
      this.form.name = ''
      this.form.gender = ''
      this.form.state = ''
      this.form.code = ''
      this.form.mobile = ''
      this.form.email = ''
      this.visible = false
      this.visibles = false
    },
    onSubmit() {
      this.$refs.ruleForms.validate(valid => {
        if (valid) {
          if (this.Id == '') {
            this.forms.superiorId = this.params.architectureId[0]
            this.AddArchitecture(this.forms)
          } else {
            this.putArchitecture(this.forms)
          }
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    // onSubmit() {
    //   console.log("submit!", this.form);
    //   if (this.Id == "") {
    //     if (this.change == false) {
    //       this.AddUser(this.form);
    //     } else {
    //       this.forms.superiorId = this.params.architectureId;
    //       this.AddArchitecture(this.forms);
    //     }
    //   } else if (this.Id != "") {
    //     if (this.change == false) {
    //       this.putUser(this.form);
    //     } else {
    //       this.putArchitecture(this.forms);
    //     }
    //   }
    // },

    onpagesize(e) {
      this.params.current = e
      this.getArchitecture()
      // console.log(this.params.current);
    },
    // 请求
    async getuserlist() {
      const res = await http.getuserlist()
      const { success, data } = res.data
      if (success) {
        this.userlist = data.users
      }
    },
    async getarchitectureUser(architectureId) {
      try {
        const res = await http.getarchitectureUser(
          this.params.current,
          this.params.pageSize,
          architectureId
        )
        const { success, data } = res.data
        if (success) {
          this.Userlist = data.users.records
          this.total = data.users.total
          //   console.log(data);
        }
      } catch (ex) {
        console.log('ex:', ex)
      }
    },
    async getUsernId(id) {
      try {
        const res = await http.getUsernId(id)
        const { success, data } = res.data
        if (success) {
          this.form.code = data.user.code
          this.form.email = data.user.email
          this.form.gender = JSON.stringify(data.user.gender)
          this.form.mobile = data.user.mobile
          this.form.name = data.user.name
          this.form.username = data.user.username
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    // 编辑
    onEdit(e) {
      //   console.log(e);
      this.Id = e.id
      this.form.id = e.id
      this.visible = true
      this.title = '编辑'
      this.getUsernId(e.id)
    },
    onArchEdit() {
      this.getArchiteid(this.params.architectureId)
      this.change = true
      this.visible = true
    },
    showArchDrawer() {
      this.change = true
      this.showDrawer()
    },
    onchangestate(e) {
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      console.log(e)
      this.stateUser(e.id, this.form.state)
    },
    //邀请员工
    async getInvitUser(architectureId, userId) {
      try {
        const res = await http.getInvitUser(architectureId, userId)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    async stateUser(id, state) {
      try {
        const res = await http.stateUser(id, state)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    // 删除
    async deleteArchitecture(key) {
      try {
        const res = await http.deleteArchitecture(key)
        const { success, msg } = res.data
        if (success) {
          this.getArchitectlist()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    // async stateArchitecture(id, state) {
    //   try {
    //     const res = await http.stateArchitecture(id, state);
    //     console.log(res);
    //     this.timer();
    //   } catch (ex) {
    //     console.log("error:", ex);
    //   }
    // },

    cancel() {
      this.$message.error('已取消操作')
    },
    timer() {
      return setTimeout(() => {
        this.getarchitectureUser(this.params.architectureId)
      }, 200)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    onout() {
      this.$router.push({ name: 'login' })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  mounted() {
    // this.getArchitecture();
    this.getArchitectlist()
    this.getuserlist()
    const users = JSON.parse(localStorage.getItem('store-userlist'))
    if (users == null) {
      this.logvisible = true
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log(this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-btn {
  margin: 0px 5px 8px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
::v-deep .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 0;
}
.Enter-top {
  padding: 0 24px;
}
.Enter-top,
.Arch-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      width: 80px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.Arch-bottom-left {
  min-width: 300px;
  width: 20%;
  height: 640px;
  border: 1px solid #d9d9d9;
  .bottom-left-title {
    height: 50px;
    background-color: #dae6ff;
    text-align: center;
    line-height: 50px;
  }
}
.Arch-bottom-right {
  min-width: 700px;
  width: 80%;
  .rightarch-top {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 50px;
    background-color: #edeef0;
  }
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
// ::v-deep .ant-tree-child-tree > li:first-child {
//   color: red;
//   background-color: #fafafa;
// }
::v-deep .xue {
  background-color: #ccc;
  color: #999;
}
.arch-top-edit {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  padding-right: 40px;
}
.arch-top-edit span {
  cursor: pointer;
}
</style>
